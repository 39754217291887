import React from "react"
import { graphql } from "gatsby"
import Container from "../components/common/Container"
import GridTwo from "../components/common/GridTwo"
import GridThree from "../components/common/GridThree"
import CardJourney from "../components/common/CardJourney"
import Layout from "../components/layouts/Default"
import { H2 } from "../components/typography/Headings"
import P from "../components/typography/P"
import Breadcrumb from "../components/common/Breadcrumb"
import PageHeader from "../components/common/PageHeader"

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Fotoreisen & Fotoexkursionen" desc="">
      <PageHeader title="Fotoreisen & Fotoexkursionen" image={data.header} />
      <Breadcrumb
        pages={[
          {
            name: "Fotoreisen & Fotoexkursionen",
            to: "/fotoreisen-fotoexkursionen/",
          },
        ]}
      />

      <Container>
        <div className="mb-8">
          <H2 className="font-display">Fotoreisen</H2>
          <h3 className="mb-5 italic font-italic">
            Mit uns der Natur auf der Spur!
          </h3>
          <GridTwo>
            <div className="space-y-5">
              <P>
                Unsere erlebnisreichen Fototouren stehen unter diesem Motto –
                und wir möchten Sie dafür begeistern. Unsere
                NATURBLICK-Referenten begleiten Naturbegeisterte wie Sie bei
                Fotoworkshops, Fotoexkursionen und Fotoreisen und unterstützen
                Sie dabei, Ihren BLICK für die NATUR zu schärfen.{" "}
              </P>
            </div>
          </GridTwo>
        </div>
        <GridThree>
          {data.fotoreisen.nodes.map((reise, i) => {
            const dates = []
            if (reise?.dates) {
              reise.dates.forEach(date => {
                dates.push(date.value)
              })
            }
            return (
              <CardJourney
                image={reise.titleImage}
                title={reise.title}
                date={dates}
                link={`/fotoreisen-fotoexkursionen/${reise.title_slug}/`}
                key={i}
              />
            )
          })}
        </GridThree>
      </Container>

      <Container>
        <div className="mb-8">
          <H2 className="mb-5 font-display">Fotoexkursionen</H2>
          <GridTwo>
            <div className="space-y-5">
              <P>
                Finden mit mindestens vier Teilnehmern statt und werden mit
                maximal sieben Teilnehmern durchgeführt (einige Tierfoto- und
                Ansitzexkursionen mit mindestens zwei und maximal nur vier
                Teilnehmern!).
              </P>
              <P>
                Die Veranstaltungen dauern acht Tage (der erste und der letzte
                Tag sind die An-/Abreisetage). Während der Fotoexkursionen
                werden wir uns hauptsächlich auf das praktische Fotografieren
                konzentrieren.
              </P>
              <P>
                Bei allen Fotoexkursionen gibt es die Möglichkeit zur
                Ansitzfotografie in einer unseren vielen Ansitzhütten oder in
                einem bequemen Tarnzelt (überall möglich, da wir immer genügend
                Tarnzelte dabei haben). Unterschiedliche Leihstative von
                Berlebach, Manfrotto, Gitzo und Sachtler können kostenlos
                benutzt werden. Stativköpfe von Novoflex und Fluidköpfe von
                Manfrotto, Gitzo, Vinten und Sachter stehen Ihnen in begrenzter
                Anzahl frei zur Verfügung (je nach Verfügbarkeit).
              </P>
              <P>
                Bei Ansitzexkursionen stehen Ihnen weitere Tarnutensilien zur
                Verfügung. Weiteres Zubehör ist auf Anfrage und vorheriger
                Absprache möglich.
              </P>
            </div>
          </GridTwo>
        </div>
        <GridThree>
          {data.exkursionen.nodes.map((reise, i) => {
            const dates = []
            if (reise?.dates) {
              reise.dates.forEach(date => {
                dates.push(date.value)
              })
            }
            return (
              <CardJourney
                image={reise.titleImage}
                title={reise.title}
                date={dates}
                link={`/fotoreisen-fotoexkursionen/${reise.title_slug}/`}
                linkText="Zur Fotoexkursion"
                key={i}
              />
            )
          })}
        </GridThree>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(
      relativePath: { eq: "pages/fotoreisen-fotoexkursionen/header.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(transformOptions: { cropFocus: ATTENTION }, width: 1600)
      }
    }
    fotoreisen: allFotoreisen(filter: { type: { eq: "Fotoreise" } }) {
      nodes {
        dates {
          value
        }
        excerpt
        title_slug
        title
        published
        place
        titleImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.5, width: 1024)
          }
        }
      }
    }
    exkursionen: allFotoreisen(filter: { type: { eq: "Fotoexkursion" } }) {
      nodes {
        dates {
          value
        }
        excerpt
        title_slug
        title
        published
        place
        titleImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, aspectRatio: 1.5, width: 1024)
          }
        }
      }
    }
  }
`
